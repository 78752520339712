.footer-logo-pc-wrapper {
    display: none;

    @include bp(lg) {
        display: block;
        z-index: 1;
        position: relative;
        margin-right: auto;
        margin-left: auto;
        margin-bottom: -46px;
        width: 100%;
        overflow-x: hidden;

        .content-wrapper {
            max-width: 1280px;
        }

        .footer-logo-pc {
            margin-left: auto;
            transform: translateX(46px);
            width: 442px;
            height: auto;
            fill: #5CB2DD;
        }
    }
}

footer {
    position: relative;
    padding-top: 24px;
    background-color: $blue-1;
    width: 100%;
    height: auto;

    @include bp(lg) {
        padding-top: 64px;
    }

    .back-to-top {
        display: none;

        @include bp(lg) {
            position: absolute;
            right: 20px;
            bottom: 24px;
            display: flex;
            flex-direction: row;
            align-items: center;
            width: fit-content;
            writing-mode: vertical-lr;
            color: $blue-3;
            font-family: $font-en;
            font-weight: 700;
            letter-spacing: 0.08em;

            &:hover {
                .circle-1 {
                    margin-bottom: 9px;
                }
        
                .circle-2 {
                    margin-bottom: 18px;
                }
            }

            .circle-1,
            .circle-2 {
                width: 8px;
                height: 8px;
                background-color: $blue-3;
                border-radius: 4px;
                transition: all 300ms;
            }

            .circle-1 {
                margin-bottom: 4px;
            }

            .circle-2 {
                margin-bottom: 13px;
            }
        }
    }

    .content-wrapper {
        position: relative;
    }

    .footer-logo {
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 32px;
        fill: #5CB2DD;
        width: 162px;
        height: auto;
    }

    .contact-info {
        margin-bottom: 32px;
        width: 100%;
        height: auto;

        @include bp(lg) {
            margin-bottom: 40px;
        }

        .text-1 {
            margin-bottom: 24px;
            text-align: center;
            color: $white;
            font-weight: 700;

            @include bp(lg) {
                margin-bottom: 24px;
                font-size: 1.8rem;
            }
        }

        .info-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;

            @include bp(lg) {
                flex-direction: row;
                justify-content: space-between;
                margin: 0 auto;
                max-width: 760px;
                width: 76%;
            }
        }

        .contact-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $white;
            padding: 16px 0;
            width: 100%;
            height: auto;

            @include bp(lg) {
                padding: unset;
                max-width: 360px;
                width: 87%;
                height: 80px;
                font-size: 1.8rem;
            }
            
            &.mail {
                position: relative;
                margin-bottom: 8px;
                font-weight: 700;
                
                @include bp(lg) {
                    transition: all 300ms;
                    margin-bottom: unset;

                    &:hover {
                        background-color: $gray-1;
                        color: #fff;
    
                        .mail-icon {
                            fill: #fff;
                        }
    
                        .arrow {
                            fill: #fff;
                        }
                    }
                }

                .mail-icon {
                    transform: translateY(1px);
                    margin-right: 8px;
                    fill: #000;
                    transition: all 300ms;
                    width: 20px;
                }

                .arrow {
                    position: absolute;
                    top: 50%;
                    right: 16px;
                    transform: translateY(-50%);
                    transition: all 300ms;
                    width: 6px;
                    height: auto;

                    @include bp(lg) {
                        width: 8px;
                    }
                }
            }

            &.tel {
                padding-right: 24px;
                padding-left: 24px;

                @include bp(lg) {
                    pointer-events: none;
                }
                
                .tel-icon {
                    margin-right: 8px;
                    fill: #333;
                    width: 22px;
                }

                .tel-number {
                    font-family: $font-en;
                    font-size: 2.6rem;
                    font-weight: 700;
                }

                .tel-open {
                    font-size: 1.4rem;
                    font-feature-settings: "palt";
                }
            }
        }
    }

    .footer-nav {
        margin-right: -37px;
        padding-top: 32px;
        padding-right: 37px;
        padding-left: 24px;
        background-color: $blue-3;
        border-radius: 20px 0 0 0;
        width: calc(100% + 37px);
        height: auto;

        @include bp(lg) {
            margin-right: unset;
            padding: 40px 72px;
            padding-top: 40px;
            padding-right: 72px;
            padding-bottom: 40px;
            padding-left: 72px;
            border-radius: 40px 40px 0 0;
            width: 100%;
        }
        
        .nav {
            position: relative;
            margin-bottom: 16px;
            padding-bottom: 16px;
            width: 100%;
            font-size: 1.4rem;
            border-bottom: solid 2px $blue-1;

            &::before {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                transform: translateX(-100%) translateY(100%);
                width: 24px;
                height: 2px;
                background-color: $blue-1;

                @include bp(lg) {
                    display: none;
                }
            }

            @include bp(lg) {
                margin-bottom: 24px;
                padding-bottom: 24px;
            }

            .nav-first {
                display: block;
                width: fit-content;
                margin-bottom: 8px;
                transition: all 300ms;

                &:hover {
                    color: $blue-1;
                }
            }

            .nav-secound {
                display: flex;
                flex-direction: column;

                @include bp(lg) {
                    flex-direction: row;
                }

                &__wrapper {
                    &.pc {
                        display: none;

                        @include bp(lg) {
                            display: flex;
                            align-items: center;

                            a {
                                display: block;
                                margin-right: 24px;
                                transition: all 300ms;

                                &:hover {
                                    color: $blue-1;
                                }

                                &:last-child {
                                    font-size: 1.2rem;
                                }
                            }
                        }
                    }

                    &.sp {
                        margin-bottom: 8px;
                        width: 100%;

                        @include bp(lg) {
                            display: none;
                        }

                        .nav-row {
                            margin-bottom: 8px;

                            a {
                                padding-right: 24px;

                                &:last-child {
                                    padding-right: unset;
                                }
                            }
                        }
                    }
                }

                .instagram-link {
                    display: block;

                    svg {
                        width: 16px;
                        transition: all 300ms;
                    }

                    @include bp(lg) {
                        margin-left: auto;

                        &:hover {
                            svg {
                                fill: $blue-1;
                            }
                        }
                    }
                }
            }
        }

        .footer-info {
            display: flex;
            flex-direction: column;

            @include bp(lg) {
                flex-direction: row;
                align-items: center;
            }

            .logo {
                margin-bottom: 16px;
                width: 144px;

                @include bp(lg) {
                    margin-right: 72px;
                }
            }

            .detail {
                font-size: 1.4rem;

                .address {
                    margin-bottom: 8px;
                    display: flex;
                    flex-direction: column;

                    @include bp(lg) {
                        flex-direction: row;
                    }

                    .link-map {
                        position: relative;
                        display: flex;
                        background-color: $black;
                        align-items: center;
                        justify-content: center;
                        margin-top: 8px;
                        padding: 8px 22px 10px 19px;
                        border-radius: 20px;
                        font-size: 1.2rem;
                        font-weight: 500;
                        color: $white;
                        width: fit-content;
                        font-size: 1.2rem;
                        
                        @include bp(lg) {
                            margin-top: auto;
                            margin-left: 19px;
                            padding: 6px 22px 8px 19px;
                            transition: all 300ms;

                            &:hover {
                                background-color: $blue-1;
                            }
                        }

                        &::after {
                            content: '';
                            position: absolute;
                            right: 9px;
                            top: 50%;
                            transform: translateY(-50%);
                            width: 0;
                            height: 0;
                            border-top: solid 4px transparent;
                            border-left: solid 5px $white;
                            border-bottom: solid 4px transparent;
                            transition: all 300ms;
                        }
                    }
                }

                .tel-fax {
                    font-family: $font-en;

                    &.pc {
                        display: none;

                        @include bp(lg) {
                            display: block;
                        }
                    }

                    &.sp {
                        margin-bottom: 32px;

                        @include bp(lg) {
                            display: none;
                        }
                    }
                }
            }

            .copyright {
                margin-right: auto;
                margin-left: auto;
                padding-bottom: 8px;
                font-size: 1.2rem;
                font-family: $font-en;
                color: $blue-1;

                @include bp(lg) {
                    margin-top: auto;
                    margin-right: unset;
                    margin-left: auto;
                }
            }
        }
    }
}