
html {
	font-size: 62.5%
}

body {
	font-family: $font-jp;
	font-weight: 400;
	font-size: 1.6rem;
	color: $black;
	text-align: justify;
}

.container {
	margin-bottom: 80px;
   	width: 100%;
   	height: auto;

	@include bp(lg) {
		margin-bottom: unset;
	}
}

.content-wrapper {
    padding: 0 37px;
	width: 100%;
	height: auto;

    @include bp(lg) {
        margin-right: auto;
	    margin-left: auto;
        padding: 0 0;
	    max-width: 1000px;
    }
}

.no-sp {
	display: none;

	@include bp(lg) {
		display: block;

		&.inline {
			display: inline;
		}
	}
}

.no-pc {
	display: block;

	&.inline {
		display: inline;
	}

	@include bp(lg) {
		display: none;
	}
}