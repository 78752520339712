// common-logo
.common-page-logo {
    position: absolute;
    z-index: 99;
    top: 8px;
    left: 8px;
    max-width: 100px;
    width: 18%;

    @include bp(lg) {
        display: none;
    }

    svg {
        width: 100%;
        height: auto;

        .a{
            fill:#0086ca;
        }

        .b{
            fill:#5cb2dd;
        }
        
        .c,
        .e {
            fill:none;
        }

        .c {
            stroke:#0086ca;
            stroke-miterlimit: 10;
            stroke-width: 1.404px;
        }
        
        .d {
            stroke:none;
        }
    }
}

// sp header common
.header-sp {
    position: sticky;
    top: 0;
    z-index: 10;
    display: flex;
    align-items: center;
    padding-left: 16px;
    background-color: transparent;
    width: 100%;
    height: 60px;

    &.top {
        background-color: $white;
    }

    @include bp(lg) {
        display: none;
    }

    &__logo {
        width: 200px;
        height: auto;
    }

    &__menu-btn {
        margin-left: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: $white;
        width: 60px;
        height: 100%;

        .line {
            display: block;
            background-color: $black;
            margin-bottom: 6px;
            width: 22px;
            height: 2px;

            &.bottom {
                margin-bottom: 3px;
            }
        }

        p {
            width: fit-content;
            font-size: 1rem;
            transform: scale(0.8);
            font-family: $font-en;
            font-weight: 700;
        }
    }
}

// full screen nav
.sp-nav {
    position: fixed;
    z-index: -2;
    top: 0;
    opacity: 0;
    transition: all 300ms;
    background-color: $blue-1;
    padding-top: 92px;
    padding-right: 37px;
    padding-left: 37px;
    width: 100vw;
    height: 100vh;

    &__close-btn {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: $white;
        width: 60px;
        height: 60px;

        .close-mark {
            position: relative;
            margin-bottom: 4px;
            width: 18px;
            height: 18px;

            .line {
                position: absolute;
                top: 50%;
                right: 50%;
                display: block;
                background-color: $black;
                width: 22px;
                height: 2px;

                &.top {
                    transform: translateX(50%) translateY(50%) rotate(45deg);
                }

                &.bottom {
                    transform: translateX(50%) translateY(50%) rotate(-45deg);
                }
            }          
        }

        p {
            font-size: 1rem;
            font-family: $font-en;
            font-weight: 700;
            transform: scale(0.8);
        }
    }

    &__nav {
        margin-bottom: 32px;
        width: 100%;
        height: auto;

        li {
            margin-bottom: 16px;
            padding-bottom: 16px;
            border-bottom: solid 1px $blue-3;
            width: 100%;
            height: auto;

            &:last-child {
                margin-bottom: 0;
                padding-bottom: 0;
                border-bottom: none;

                a {
                    font-size: 1.2rem;
                }
            }

            a {
                display: block;
                width: 100%;
                height: auto;
                font-size: 1.8rem;
                color: $white;
                line-height: 1.125;
            }
        }
    }

    &__instagram {
        line-height: 1;

        svg {
            width: 22px;
            height: auto;
            fill: $white;
        }
    }
}

#sp-nav-toggle:checked {
    ~ .sp-nav {
        opacity: 1.0;
        z-index: 999;
    }
}